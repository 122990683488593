import { useQuery } from 'react-query';

// Import helpers
import { GET_SETTINGS_GLOBAL, QUERY_SETTINGS_ADS } from 'helpers/api';

// Import utilities
import { useSubscriptions, useAuth } from 'components/utilities';

// Import services
import services from 'services/services';

const usePlayerAds = ({ isPreview = false, isChannelPlayer = false }) => {
	const { isAuth } = useAuth();
	const { isSubscriptions, isSubscriptionsLoading } = useSubscriptions({
		isLogged: isAuth
	});

	const getAdsSettings = async () => {
		const { data } = await services.get(GET_SETTINGS_GLOBAL);
		const webLink = data?.features?.vast.web_link || null;

		const httpRegex = /^http/;

		if (webLink && httpRegex.test(webLink)) {
			return webLink;
		}

		return null;
	};

	const { data: adsUrl, isLoading: isAdsSettingsLoading } = useQuery({
		queryKey: QUERY_SETTINGS_ADS,
		queryFn: getAdsSettings
	});

	const isAdsLoading = isAdsSettingsLoading || isSubscriptionsLoading;

	const isAdsAvailable = isChannelPlayer || (!isSubscriptions && !isPreview);

	const adsSettingsUrl = isAdsAvailable ? adsUrl : '';

	return { isAdsLoading, adsSettingsUrl };
};

export default usePlayerAds;
