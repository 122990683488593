export const checkIsFavourite = (favourites, channelId) =>
	!!favourites.find(({ uuid }) => uuid === channelId);

export const getChannelsWithPrograms = (lives) =>
	lives.filter((item) => item.noPrograms !== true);

export const getFavouriteChannels = (
	lives,
	favourites,
	withPrograms = true
) => {
	const channels = withPrograms ? getChannelsWithPrograms(lives) : lives;

	return channels.reduce((acc, next) => {
		const channel = favourites.find(({ uuid }) => uuid === next.uuid);
		if (channel) {
			acc.push(next);
		}
		return acc;
	}, []);
};
export const getFilteredChannels = (category, lives, withPrograms = true) => {
	const channels = withPrograms ? getChannelsWithPrograms(lives) : lives;
	return category !== 'all'
		? channels.filter(({ genres }) => {
				return genres.length && genres.find(({ slug }) => slug === category);
		  })
		: channels;
};

export const getLivePrograms = (item) => {
	const { epgProgrammes, currentLiveIndex, noPrograms } = item;
	if (epgProgrammes[currentLiveIndex] && !noPrograms) return item;
};

export const flatten = (lives, hideUnavailable) =>
	lives.reduce((acc, next) => {
		const { subscriberLocalLimited, epgProgrammes, avaiable } = next;
		// Check if channel is limited;
		const limited = subscriberLocalLimited || !avaiable;
		const isAvailable = hideUnavailable && limited;
		// Return null if settings hideUnavailable, not paid and subscribeLocalLimited are turn on
		!isAvailable && acc.push(...epgProgrammes);
		return acc;
	}, []);

export const getChanelsResources = (lives, hideUnavailable) =>
	[].concat(lives).reduce((acc, live) => {
		const { subscriberLocalLimited, avaiable, noPrograms } = live;
		// Check if channel is limited;
		const limited = subscriberLocalLimited || !avaiable;
		const isAvailable = hideUnavailable && limited;

		// Return null if settings hideUnavailable, not paid and subscribeLocalLimited are turn on
		!isAvailable &&
			!noPrograms &&
			acc.push({
				images: live.images,
				subscriberLocalLimited: live.subscriberLocalLimited,
				paid: live.avaiable,
				catchupAvailable: live.catchupAvailable,
				id: live.id,
			});
		return acc;
	}, []);

export const measureEpg = () => ({
	epgWidth: 7200,
	epgItemWidth: 300,
	epgItemHeight: 70,
	resize: false,
});

export const getFavouriteCreatedAt = (favourites, channelId) => {
	const favourite = favourites.find(({ uuid }) => uuid === channelId);
	return favourite ? favourite.favourite_created_at : null;
};

export const sortChannelsByFavourite = (channels, favourites) => {
	return channels.sort((a, b) => {
		const aFavouriteDate = getFavouriteCreatedAt(favourites, a.uuid);
		const bFavouriteDate = getFavouriteCreatedAt(favourites, b.uuid);

		if (aFavouriteDate && bFavouriteDate)
			return new Date(bFavouriteDate) - new Date(aFavouriteDate);
		if (aFavouriteDate) return -1;
		if (bFavouriteDate) return 1;

		return a.title.localeCompare(b.title);
	});
};
